<template>
  <div>
    <CRow>
      <CCol sm="12">
        <p>
          Carga aqui el listado de licencias para activar.
        </p>
        <form @submit.prevent="submit">
          <input type="file" @change="handleFileUpload( $event )">
          <br><br>
          <CButton
            @click="send()"
            color="primary"
          >
            Enviar
          </CButton>
        </form>
      </CCol>
      
    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
    </CRow>
  </div>
</template>

<script>

export default {
  name: 'Masks',
  data(){
    return {
      file: '',
      toasts: [],
    }
  },
  methods: {
    handleFileUpload( event ){
      this.file = event.target.files[0];
    },

    async send() {
      let formData = new FormData();
      formData.append('file', this.file);
      await axios.post( 'https://training.softwarecenter.com.ec/api/masks/activate',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Accept':'application/json'
          }
        }
      ).then((data) => {
        this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Activado con exito",
          });
      })
      .catch((error) => {
        var errors = error.response.data.errors;
        var error = {};
        Object.keys(errors).forEach((key) => {
          error = {
            title: "Error al activar licencias",
            color: "danger",
            message: errors[key][0],
          };
        });
        this.toasts.push(error);
      });
    }
  }
}
</script>
